import React from 'react';
import './callToAction.css';

export function CallToAction() {
    return (
        <section id="cta" className="cta">
            <div className="container" data-aos="zoom-in">

                <div className="text-center">
                    <h3>Choose Us</h3>
                    <p> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                        mollit anim id est laborum.</p>
                    <a className="cta-btn" href="tel:0112223333">Call Now</a>
                </div>

            </div>
        </section>
    );
}
