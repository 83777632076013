import React from 'react';
import aboutImage from './about.jpg';
import './about.css';

export function About() {
    return (
        <section id="about" className="about">
            <div className="container" data-aos="fade-up">

                <div className="row">
                    <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                        <img src={aboutImage} className="img-fluid" alt=""/>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right"
                         data-aos-delay="100">
                        <h3>Who we are</h3>
                        <p className="font-italic">
                            <span>Rathe Holdings</span> is a group of companies that consults to the private sector
                            handling Strategic, Public Relations and Technology projects.
                            <br/>Companies under its helm are:
                        </p>
                        <ul>
                            <li>
                                <i className="ri-check-double-line"/> Rathe Consulting – management consulting firm
                                assisting private sector with strategic and operational projects.
                            </li>
                            <li>
                                <i className="ri-check-double-line"/> Rathe Media Agency – managing public relations and
                                media for the private sector.
                            </li>
                            <li>
                                <i className="ri-check-double-line"/> Rathe Technologies – managing Information
                                Technology software implementation and other relevant projects.
                            </li>
                        </ul>

                    </div>
                </div>

            </div>
        </section>
    );
}
