import React from 'react';
import './footer.css';

export function Footer() {
    return (
        <footer id="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-md-6">
                            <div className="footer-info">
                                <h3>Rathe Holdings<span>.</span></h3>
                                <p>
                                    Waterfall Spaces <br/>
                                    GP 2001, ZA<br/><br/>
                                    <strong>Phone:</strong> +27 11 222 3333<br/>
                                    <strong>Email:</strong> info@ratheholdings.co.za<br/>
                                </p>
                                <div className="social-links mt-3">
                                    <a href="#" className="twitter"><i className="bx bxl-twitter"/></a>
                                    <a href="#" className="facebook"><i className="bx bxl-facebook"/></a>
                                    <a href="#" className="instagram"><i className="bx bxl-instagram"/></a>
                                    <a href="#" className="google-plus"><i className="bx bxl-skype"/></a>
                                    <a href="#" className="linkedin"><i className="bx bxl-linkedin"/></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"/> <a href="#hero">Home</a></li>
                                <li><i className="bx bx-chevron-right"/> <a href="#about">About us</a></li>
                                <li><i className="bx bx-chevron-right"/> <a href="#services">Services</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Companies</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"/>
                                    <a href="http://ratheconsulting.yelah.co.za">Rathe
                                        Consulting</a></li>
                                <li><i className="bx bx-chevron-right"/>
                                    <a href="http://rathemedia.yelah.co.za">Rathe
                                        Media Agency</a></li>
                                <li><i className="bx bx-chevron-right"/>
                                    <a href="http://rathetechnologies.yelah.co.za">Rathe
                                        Technologies</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong><span>Rathe Holdings</span></strong>. All Rights Reserved
                </div>
                <div className="credits">
                    Developed by <a href="https://www.yelah.co.za" target={'_blank'} rel={'noopener'}>Yelah</a>
                </div>
            </div>
        </footer>
    );
}
