import React from 'react';
import './contact.css';

export function Contact() {
    return (
        <section id="contact" className="contact">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Contact</h2>
                    <p>Contact Us</p>
                </div>

                <div>
                    <iframe style={{border: '0', width: '100%', height: '270px'}}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3585.584184919619!2d28.10629321528008!3d-26.014435262603612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95734b5c6e5d2d%3A0xb3d8c5c6b3b0b415!2sSpaces%20-%20Johannesburg%2C%20Waterfall%2C%20Midrand!5e0!3m2!1sen!2sza!4v1614071772808!5m2!1sen!2sza"
                            frameBorder="0" loading="lazy" allowFullScreen/>
                </div>

                <div className="info row mt-5 align-items-center">

                    <div className="email col-lg-4">
                        <i className="icofont-envelope"/>
                        <h4>Email:</h4>
                        <p>info@ratheholdings.co.za</p>
                    </div>

                    <div className="phone col-lg-4">
                        <i className="icofont-phone"/>
                        <h4>Call:</h4>
                        <p>+27 11 222 3333</p>
                    </div>

                    <div className="address col-lg-4">
                        <i className="icofont-google-map"/>
                        <h4>Location:</h4>
                        <p>Waterfall Spaces, Midrand, GP 2001</p>
                    </div>

                </div>

            </div>
        </section>
    );
}
