import React from 'react';
import './header.css';

export function Header() {
    return (
        <header id="header" className="fixed-top ">
            <div className="container d-flex align-items-center justify-content-between">

                <h1 className="logo">
                    <a href="#hero">Rathe<span>.</span></a>
                </h1>

                <nav className="nav-menu d-none d-lg-block">
                    <ul>
                        <li className="active"><a href="#hero">Home</a></li>
                        <li><a href="#about">About</a></li>
                        <li><a href="#services">Services</a></li>
                        <li className="drop-down"><a href={'#'}>Companies</a>
                            <ul>
                                <li><a href="http://ratheconsulting.yelah.co.za">Rathe Consulting</a></li>
                                <li><a href="http://rathemedia.yelah.co.za">Rathe Media Agency</a></li>
                                <li><a href="http://rathetechnologies.yelah.co.za">Rathe Technologies</a></li>
                            </ul>
                        </li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}
