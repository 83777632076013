import React from 'react';
import './services.css';

export function Services() {
    return (
        <section id="services" className="services">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Services</h2>
                    <p>Our Services</p>
                </div>

                <div className="row">
                    <div className="image col-lg-6" id="feature-image"
                         data-aos="fade-right"/>
                    <div className="col-lg-6" data-aos="fade-left" data-aos-delay="100">
                        <div className="icon-box mt-5 mt-lg-0" data-aos="zoom-in" data-aos-delay="150">
                            <i className="bx bx-receipt"/>
                            <h4>Public Relations</h4>
                            <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
                        </div>
                        <div className="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
                            <i className="bx bx-cube-alt"/>
                            <h4>Consulting</h4>
                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
                        </div>
                        <div className="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
                            <i className="bx bx-images"/>
                            <h4>Technology</h4>
                            <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
                        </div>
                        <div className="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
                            <i className="bx bx-shield"/>
                            <h4>Marketing</h4>
                            <p>Expedita veritatis consequuntur nihil tempore laudantium vitae denat pacta</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}
